<!-- @format -->

<template>
  <section class="payment container">
    <div class="payment-box">
      <h2 class="payment__h2 text-center">
        {{
          $cookie.get("lang") === "Ru"
            ? "Политика конфиденциальности"
            : "Құпиялылық саясаты"
        }}
      </h2>
      <p></p>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.payment {
  padding-top: 30px;
  padding-bottom: 30px;

  &-box {
    padding: 50px 0;
    border-bottom: 1px solid #a5aab5;
  }
  &__h2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #1d393c;
    text-align: start;
  }
  &-flex {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-mail {
    color: #174baf;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-list {
    list-style-type: disc;
    text-align: left;
    font-family: "Lato Regular";
    font-style: normal;
    font-weight: 400;
    font-size: rem(18);
    line-height: 130%;
    color: #333333;
    margin: 20px 0;
    text-align: start;
    &__number {
      list-style-type: decimal;
      text-align: left;
      font-family: "Lato Regular";
      font-style: normal;
      font-weight: 400;
      font-size: rem(18);
      line-height: 130%;
      color: #333333;
      margin: 20px 0;
      text-align: start;
    }
  }
}

p {
  text-align: left;
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: 130%;
  color: #333333;
  margin: 20px 0;
  text-align: start;
}

br {
  display: block;
  margin: 5px 0;
}

li {
  margin: 5px 0;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.mt-50 {
  margin-top: 50px;
}

.my-5 {
  margin: 5px 0;
}
</style>
